import React from 'react';

import TopNav from '../components/TopNav/TopNav';

export default () => {    
    return (
        <div>
            <TopNav subBrand={"everything"} />
            <h1>Everything!</h1>
        </div>
    );
};