import React from 'react';

import GlitchyLogo from '../Logo/GlitchyLogo';
import NavBarItem from '../NavBarItem';
import { Link } from 'react-router-dom';
import PictureBox from '../Canvas/PictureBox';

export type TopNavProps = {
    subBrand: string
};


const shader = `
precision mediump float;     
varying vec2 v_texcoord;         
uniform sampler2D u_texture;
uniform float u_time;

float map(float value, float min1, float max1, float min2, float max2) {
  return min2 + (value - min1) * (max2 - min2) / (max1 - min1);
}

void main() {
    vec2 uv = v_texcoord.xy;
    float t = mod(u_time, 2000.0);
    float d = 0.0;
    float m = 1.0;
    vec4 pink = vec4(1.0, 0.0, 1.0, 1.0);
    vec4 black = vec4(0.0, 0.0, 0.0, 1.0);
    float s = smoothstep(0.0, 2000.0, t);

    if ( s > 0.1 && s <= 0.2 ) {
      //  red_down-left_out_small
      d = map(s, 0.1, 0.2, 0.0, 0.04);
      m = 1.0;
    }
    else if ( s > 0.2 && s <= 0.3 ){
      //  red_down-left_in_small
      d = 0.04 - map(s, 0.2, 0.3, 0.0, 0.04);
      m = 1.0;
    }
    else if ( s > 0.3 && s <= 0.4 ){
      //  red_down-right_out_small
      d = map(s, 0.3, 0.4, 0.0, 0.04);
      m = -1.0;
    }
    else if ( s > 0.4 && s <= 0.5 ) {
      //  red_down-right_in_small
      d = 0.04 - map(s, 0.4, 0.5, 0.0, 0.04);
      m = -1.0;
    }
    else if ( s > 0.5 && s <= 0.6 ) {
      //  red_down-right_in_big
      d = 0.2 - map(s, 0.5, 0.6, 0.0, 0.04);
      m = -1.0;
    }
    else if ( s > 0.6 && s < 0.8 ) {
      //  intentionally left normal
    }
    else if ( s > 0.8 && s < 0.9 ) {
      //  red_down-left_in_big
      d = 0.15 - map(s, 0.8, 0.9, 0.0, 0.04);
      m = 1.0;
    }
    
    float r = texture2D(u_texture, uv + vec2((m * d) * uv.x, -d * uv.y), 1.0).r;
    float g = texture2D(u_texture, uv, 1.0).g;
    float b = texture2D(u_texture, uv + vec2((m * -d) * uv.x, d * uv.y), 1.0).b;

    gl_FragColor = vec4(r, g, b, 1.0);
}`;

export default (props : TopNavProps) => {
    const styles = {
        logo: { 
            display: 'inline-block',
        },
        logoText: { 
            display: 'inline-block', 
            fontFamily: 'GlitchCity', 
            fontSize: '32px', 
            position: 'relative',
            top: '-10px',
            zIndex: '2',
            left: '75px'
        },
        brand: {
           // borderBottom: "1px solid white",
           // borderRight: "1px solid white",
            width: "435px",
            height: "74px",
            borderRadius: "0px 0px 5px 0px",
            display: 'inline-block',
            color: 'white'
        },
        subBrand: {
          display: 'inline-block',
          position: 'absolute',
          left: '72px',
          top: '44px',
          width: '347px'  
        },
        navLinks: {
            //borderBottom: "1px solid white",
            //borderLeft: "1px solid white",
            float: 'right',
            borderRadius: '0px 0px 0px 5px',
            paddingLeft: '16px'
        }
    };
    
    return (
        <div style={{ borderBottom: '3px solid #161616' }}>
            {/* @ts-ignore */}
            <Link to="/" style={styles.brand}>
                {/* @ts-ignore */}
                <h1 style={styles.logoText}>CREATIVE CHAOS</h1>
                <PictureBox 
                    width={140} 
                    height={105}
                    style={{ position: 'absolute', left: '-35px', top: '-17px'}} 
                    src="textures/cc-icon_v1-whiteOnBlack.png" 
                    fragmentShader={shader} />
                {/* @ts-ignore */}
                <b style={styles.subBrand}>{props.subBrand}</b>
            </Link>

            {/* @ts-ignore */}
            <div style={styles.navLinks}>
                <NavBarItem shortChar={"A"} name={"about"} href={"/about"} />
                <NavBarItem shortChar={"B"} name={"blog"} href={"/blog"} />
                <NavBarItem shortChar={"C"} name={"creations"} href={"/creations"} />
                <NavBarItem shortChar={"D"} name={"drafts"} href={"/drafts"} />
                <NavBarItem shortChar={"E"} name={"everything"} href={"/everything"} />
            </div>
        </div>
    );
};