import React from 'react';
import { Link } from 'react-router-dom';

export type NavBarItemProps = {
    name : string,
    shortChar : string,
    href? : string
};

export default (props : NavBarItemProps)  => {
    if ( props.href ) {
        return (
            <Link to={props.href} style={{ color: 'white' }}>
                <div className="navbar-item">
                    <div className={`navbar-item-short-char ${props.name}`}>{props.shortChar}</div>
                    <div className="navbar-item-name">{props.name}</div>
                </div>
            </Link>
        )
    }

    return (
        <div className="navbar-item">
        <div className={`navbar-item-short-char ${props.name}`}>{props.shortChar}</div>
            <div className="navbar-item-name">{props.name}</div>
        </div>
    );
};