import React from 'react';
import './Pages.css';
import TopNav from '../components/TopNav/TopNav';

export default () => {    
    return (
        <div>
            <TopNav subBrand={"about"} />
            <div className="content-container">
                <h1 className="inline right-bump-1">Who</h1>
                <h4 className="inline">made this site?</h4>
                <p>
                    A self-taught software developer who strives to be a jack-of-all-trades, master of some. 
                    Currently I'm using C# and React heavily while learning Rust as a hobby with many other languages in my past experience.
                    I have an interest in practically anything technology related and have dabbled in everything from microcontroller programming to cloud services.
                    As of 2021, I have about 13 years of experience developing software with a recent professional focus on full-stack web development.
                </p>

                <hr />

                <h1 className="inline right-bump-1">What</h1>
                <h4 className="inline">is the site for?</h4>
                <p>
                    To be a place for organizing the many random things I do in my spare time! 
                    Over the years I've done lots of projects without really having a great spot to display them for feedback so I'm hoping this will be a good place for that need.
                    Hopefully others get a kick out of some things I end up making and get inspired to do something chaotically creative.</p>
            
                <hr />

                <h1 className="inline right-bump-1">Why</h1>
                <h4 className="inline">so many random things??</h4>
                <p>
                    Life is random, might as well be a bit random yourself and have a fun time doing it :) 
                    I try to pick up any hobby which sounds interesting or helps complete a project goal so buckle up for lots of whiplash on project variety!
                </p>
            </div>                       
        </div>
    );
};