import React from 'react';
import './LandingPage.css';
import useMediaQuery from '../hooks/useMediaQuery';

import NavBarItem from '../components/NavBarItem';
import PictureBox from '../components/Canvas/PictureBox';

const shader = `
precision mediump float;     
varying vec2 v_texcoord;         
uniform sampler2D u_texture;
uniform float u_time;

float map(float value, float min1, float max1, float min2, float max2) {
  return min2 + (value - min1) * (max2 - min2) / (max1 - min1);
}

void main() {
    vec2 uv = v_texcoord.xy;
    float t = mod(u_time, 2000.0);
    float d = 0.0;
    float m = 1.0;
    vec4 pink = vec4(1.0, 0.0, 1.0, 1.0);
    vec4 black = vec4(0.0, 0.0, 0.0, 1.0);
    float s = smoothstep(0.0, 2000.0, t);

    if ( s > 0.1 && s <= 0.2 ) {
      //  red_down-left_out_small
      d = map(s, 0.1, 0.2, 0.0, 0.04);
      m = 1.0;
    }
    else if ( s > 0.2 && s <= 0.3 ){
      //  red_down-left_in_small
      d = 0.04 - map(s, 0.2, 0.3, 0.0, 0.04);
      m = 1.0;
    }
    else if ( s > 0.3 && s <= 0.4 ){
      //  red_down-right_out_small
      d = map(s, 0.3, 0.4, 0.0, 0.04);
      m = -1.0;
    }
    else if ( s > 0.4 && s <= 0.5 ) {
      //  red_down-right_in_small
      d = 0.04 - map(s, 0.4, 0.5, 0.0, 0.04);
      m = -1.0;
    }
    else if ( s > 0.5 && s <= 0.6 ) {
      //  red_down-right_in_big
      d = 0.2 - map(s, 0.5, 0.6, 0.0, 0.04);
      m = -1.0;
    }
    else if ( s > 0.6 && s < 0.8 ) {
      //  intentionally left normal
    }
    else if ( s > 0.8 && s < 0.9 ) {
      //  red_down-left_in_big
      d = 0.15 - map(s, 0.8, 0.9, 0.0, 0.04);
      m = 1.0;
    }
    
    float r = texture2D(u_texture, uv + vec2((m * d) * uv.x, -d * uv.y), 1.0).r;
    float g = texture2D(u_texture, uv, 1.0).g;
    float b = texture2D(u_texture, uv + vec2((m * -d) * uv.x, d * uv.y), 1.0).b;

    gl_FragColor = vec4(r, g, b, 1.0);
}`;

function Landing() {
  const [ logoSize, setLogoSize ] = React.useState({ 
    width: 750,
    fontSize: 60,
    topMargin: -72
  });
  const under750 = useMediaQuery("(max-width: 750px)");
  const under450 = useMediaQuery("(max-width: 450px)");

  
  if ( under450 && logoSize.width != 300 ) {
    setLogoSize({
      width: 300,
      fontSize: 48,
      topMargin: -72
    })
  }
  else if ( !under450 ) {
    if ( under750 && logoSize.width != 500 ) {
      setLogoSize({
        width: 500,
        fontSize: 60,
        topMargin: -75
      });
    }
    else if ( under750 == false && logoSize.width != 750 ) {
      setLogoSize({
        width: 750,
        fontSize: 60,
        topMargin: -72
      });
    }
  }  

  const logoStyles = React.useMemo(() => ({
    //border: '1px solid red',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: `${logoSize.width * 2}px`, 
    position: 'relative',
    top: `-${logoSize.width / 2}px`,
    zIndex: 1,
  }), [ logoSize.width, logoSize.topMargin ]);

  const brandStyles = React.useMemo(() => ({ 
    fontSize: `${logoSize.fontSize}px`, 
    zIndex: 2, 
    position: "sticky",
    marginTop: '15px', 
    marginLeft: '15px', 
    color: "white" 
  }), [ logoSize.fontSize ]);

  const smallNavBar = { 
    backgroundColor: '#ededed',
    borderRadius: '15px',
    border: '1px solid #aaa',
    margin: '10px 25px 0px 25px',
    padding: '10px',
    display: 'flex',
    flexDirection: 'row',
    zIndex: 2,
    position: 'sticky',
    justifyContent: 'space-between' 
  };

  const bigNavBar = {
    backgroundColor: 'black',
    borderRadius: '15px',
    border: '2px solid #aaa',
    color: 'white',
    margin: '10px 25px 0px 25px',
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 2,
    position: 'fixed',
    justifyContent: 'space-between',
    textAlign: 'center',
    width: '100px',
    height: '50vh',
    right: '0px' 
  };

  return (
    <>
      { under750 && 
        /* @ts-ignore */
        <div className={"navbar-container horizontal"}>
          <NavBarItem shortChar={'A'} name="about" href={'/about'} />
          <NavBarItem shortChar={'B'} name="blog" href={'/blog'} />
          <NavBarItem shortChar={'C'} name="creations" href={'/creations'} />
          <NavBarItem shortChar={'D'} name="drafts" href={'/drafts'} />
          <NavBarItem shortChar={'E'} name="everything" href={'/everything'} />       
        </div>
      }

      {/* @ts-ignore */}
      { !under750 && <h1 id="brand" style={brandStyles}>CREATIVE <br/> CHAOS</h1>}

      { !under750 && 
        /* @ts-ignore */
        <div className={"navbar-container vertical"}>
          <NavBarItem shortChar={'A'} name="about" href={'/about'} />
          <NavBarItem shortChar={'B'} name="blog" href={'/blog'} />
          <NavBarItem shortChar={'C'} name="creations" href={'/creations'} />
          <NavBarItem shortChar={'D'} name="drafts" href={'/drafts'} />
          <NavBarItem shortChar={'E'} name="everything" href={'/everything'} />      
        </div>
      }

      {/* @ts-ignore */}
      { under750 && <h1 id="brand" style={{ ...brandStyles, textAlign: 'center' }}>CREATIVE</h1>}

      {/* @ts-ignore */}
      <div style={logoStyles}>
        <PictureBox 
          width={logoSize.width * 2} 
          height={logoSize.width * 1.5} 
          src="textures/cc-icon_v1-whiteOnBlack.png" 
          fragmentShader={shader} />
      </div>
      

      {/* @ts-ignore */}
      { under750 && <h1 id="brand" style={{ ...brandStyles, textAlign: 'center' }}>CHAOS</h1>}
    </>
  );
}

export default Landing;
