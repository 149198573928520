import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import PictureBox from './components/Canvas/PictureBox';

const rgbShiftShaderA = `
precision mediump float;     
varying vec2 v_texcoord;         
uniform sampler2D u_texture;
uniform float u_shift;

void main() {
    vec2 uv = v_texcoord.xy;
    float r = texture2D(u_texture, uv + vec2(-u_shift * uv.x, u_shift * uv.y), 1.0).r;
    float g = texture2D(u_texture, uv, 1.0).g;
    float b = texture2D(u_texture, uv + vec2(u_shift * uv.x, -u_shift * uv.y), 1.0).b;

    gl_FragColor = vec4(r, g, b, 1.0);
}`

const rgbShiftShaderB = `
precision mediump float;     
varying vec2 v_texcoord;         
uniform sampler2D u_texture;
uniform float u_shift;
uniform float u_time;

highp float Xrand(vec2 co)
{
    highp float a = 12.9898;
    highp float b = 78.233;
    highp float c = 43758.5453;
    highp float dt= dot(co.xy ,vec2(a,b));
    highp float sn= mod(dt,3.14);
    return fract(sin(sn) * c);
}

void main() {
    vec2 uv = v_texcoord.xy;
    
    float d = 0.0;

    if ( uv.y > Xrand(u_time, u_time) ) {
      
    }

    gl_FragColor = vec4(r, g, b, 1.0);
}`

const rgbShiftShaderC = `
precision mediump float;     
varying vec2 v_texcoord;         
uniform sampler2D u_texture;
uniform float u_shift;

void main() {
    vec2 uv = v_texcoord.xy;
    float r = texture2D(u_texture, uv + vec2(-u_shift * uv.x, -u_shift * uv.y), 1.0).r;
    float g = texture2D(u_texture, uv, 1.0).g;
    float b = texture2D(u_texture, uv + vec2(u_shift * uv.x, u_shift * uv.y), 1.0).b;

    gl_FragColor = vec4(r, g, b, 1.0);
}`


const rgbShiftShaderAuto = `
precision mediump float;     
varying vec2 v_texcoord;         
uniform sampler2D u_texture;
uniform float u_time;

float map(float value, float min1, float max1, float min2, float max2) {
  return min2 + (value - min1) * (max2 - min2) / (max1 - min1);
}

void main() {
    vec2 uv = v_texcoord.xy;
    float t = mod(u_time, 2000.0);
    float d = 0.0;
    float m = 1.0;
    vec4 pink = vec4(1.0, 0.0, 1.0, 1.0);
    vec4 black = vec4(0.0, 0.0, 0.0, 1.0);
    float s = smoothstep(0.0, 2000.0, t);
    vec4 debug_color = black;

    if ( s <= 0.1 ) {
      //  red_down-left_out_small
      d = map(s, 0.0, 0.1, 0.0, 0.04);
      debug_color = black;
      m = 1.0;
    }
    else if ( s > 0.1 && s <= 0.2 ){
      //  red_down-left_in_small
      d = 0.04 - map(s, 0.1, 0.2, 0.0, 0.04);
      debug_color = black;
      m = 1.0;
    }
    else if ( s > 0.2 && s <= 0.3 ){
      //  red_down-right_out_small
      d = map(s, 0.2, 0.3, 0.0, 0.04);
      debug_color = black;
      m = -1.0;
    }
    else if ( s > 0.3 && s <= 0.4 ) {
      //  red_down-right_in_small
      d = 0.04 - map(s, 0.3, 0.4, 0.0, 0.04);
      debug_color = black;
      m = -1.0;
    }
    else if ( s > 0.4 && s <= 0.5 ) {
      //  red_down-right_in_big
      d = 0.3 - map(s, 0.3, 0.4, 0.0, 0.04);
      debug_color = pink;
      m = -1.0;
    }
    else if ( s > 0.5 && s < 0.7 ) {
      //  intentionally left normal
    }
    else if ( s > 0.7 && s < 0.8 ) {
      //  red_down-left_in_big
      d = 0.3 - map(s, 0.3, 0.4, 0.0, 0.04);
      debug_color = pink;
      m = 1.0;
    }
    
    float r = texture2D(u_texture, uv + vec2((m * d) * uv.x, -d * uv.y), 1.0).r;
    float g = texture2D(u_texture, uv, 1.0).g;
    float b = texture2D(u_texture, uv + vec2((m * -d) * uv.x, d * uv.y), 1.0).b;

    gl_FragColor = vec4(r, g, b, 1.0);
}`


const onFrame = (delta, setUniforms, next) => {
  setUniforms(uniforms => {
    let { time, shift } = uniforms;

    if ( shift >= 0.04 ) {
      shift = 0;
    }
    else {
      shift += 0.01;
    }

    return {
      time: time + (delta * 0.0001),
      shift
    };
  });

  setTimeout(() => {
    next();
  }, 200);
};

/*
<PictureBox 
      width={400} 
      height={300} 
      fragmentShader={rgbShiftShaderAuto}
      style={{ display: 'inline-block' }}
      src="textures/cc-icon_v1-whiteOnBlack.png" />
*/

ReactDOM.render(
  <React.StrictMode>

    <App />

  </React.StrictMode>,
  document.getElementById('root')
);

