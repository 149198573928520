import React from 'react';

import TopNav from '../components/TopNav/TopNav';

export default () => {    
    return (
        <div>
            <TopNav subBrand={"creations"} />
            <h1>Creations!</h1>
        </div>
    );
};