import React from 'react';

import TopNav from '../components/TopNav/TopNav';

export default () => {    
    return (
        <div>
            <TopNav subBrand={"drafts"} />
            <h1>Drafts!</h1>
        </div>
    );
};