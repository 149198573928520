import React, { CSSProperties, MutableRefObject } from 'react';
import * as matrixHelpers from './MatrixHelpers';

export type PictureBoxProps = {
    src : string,
    width : number,
    height : number,
    vertexShader? : string,
    fragmentShader? : string,
    onFrame? : (delta: number, setUniforms: any, next: any) => void,
    style? : CSSProperties
};

export type SizedWebGLTexture = {
    height : number,
    width : number,
    glTexture : WebGLTexture | null
};



function drawImage(
    gl: WebGLRenderingContext, 
    texture: MutableRefObject<SizedWebGLTexture>,
    program: WebGLProgram,
    positionBuffer: WebGLBuffer,
    textureCoordsBuffer: WebGLBuffer,
    positionLocation: number,
    textureCoordsLocation: number,
    matrixLocation: WebGLUniformLocation,
    textureLocation: WebGLUniformLocation)  
{    
    gl.bindTexture(gl.TEXTURE_2D, texture.current.glTexture);

    gl.useProgram(program);

    gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
    gl.enableVertexAttribArray(positionLocation);
    gl.vertexAttribPointer(
        positionLocation,   // index
        2,                  // size,
        gl.FLOAT,           // type,
        false,              // normalized,
        0,                  // stride
        0);                 // offset

    gl.bindBuffer(gl.ARRAY_BUFFER, textureCoordsBuffer);
    gl.enableVertexAttribArray(textureCoordsLocation);
    gl.vertexAttribPointer(
        textureCoordsLocation,  // index
        2,                      // size,
        gl.FLOAT,               // type,
        false,                  // normalized,
        0,                      // stride
        0);                     // offset

    let matrix = matrixHelpers.orthographic(
        0,                      // left,
        texture.current.width,  // right,
        texture.current.height, // bottom,
        0,                      // top,
        0,                      // near,
        -1                      // far
    );
    
    matrix = matrixHelpers.scale(
        matrix,                 //  input
        texture.current.width,  //  x scale
        texture.current.height, //  y scale
        1                       //  z scale
    );

    gl.uniformMatrix4fv(matrixLocation, false, matrix);
    
    gl.uniform1i(textureLocation, 0);

    gl.drawArrays(gl.TRIANGLES, 0, 6);
}

function loadAndDrawImage(
    gl: WebGLRenderingContext, 
    src: string, 
    texture: MutableRefObject<SizedWebGLTexture | undefined>,
    program: WebGLProgram,
    positionBuffer: WebGLBuffer,
    textureCoordsBuffer: WebGLBuffer,
    positionLocation: number,
    textureCoordsLocation: number,
    matrixLocations: WebGLUniformLocation,
    textureLocations: WebGLUniformLocation) 
{
    if ( texture.current === undefined ) {

        //  Create the texture object using the WebGLContext
        texture.current = {
            width: 1,
            height: 1,
            glTexture: gl.createTexture()
        };
        
        //  Bind the Texture to the WebGLContext as a 2D texture
        gl.bindTexture(gl.TEXTURE_2D, (texture.current as SizedWebGLTexture).glTexture);

        //  Configure the texture parameters
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);

        const img = new Image();
        img.addEventListener('load', () => {
            //  Store the native size of the loaded image
            (texture.current as SizedWebGLTexture).width = img.width * 2;
            (texture.current as SizedWebGLTexture).height = img.height;

            //  Bind the texture to the WebGLContext
            gl.bindTexture(gl.TEXTURE_2D, (texture.current as SizedWebGLTexture).glTexture);
            
            //  Pull the loaded image into the texture object
            gl.texImage2D(
                gl.TEXTURE_2D, 
                0,                  //  level
                gl.RGBA,            //  internalFormat
                gl.RGBA,            //  format
                gl.UNSIGNED_BYTE,   //  type
                img);

            //  Draw the newly loaded texture
            drawImage(
                gl, 
                texture as MutableRefObject<SizedWebGLTexture>, 
                program, 
                positionBuffer,
                textureCoordsBuffer,
                positionLocation,
                textureCoordsLocation,
                matrixLocations,
                textureLocations);
        });

        //  Set the image source so it begins loading
        img.src = src;
    }
    else {

        //  We already have the image loaded into a texture so draw it normally
        drawImage(
            gl,  
            texture as MutableRefObject<SizedWebGLTexture>, 
            program, 
            positionBuffer,
            textureCoordsBuffer,
            positionLocation,
            textureCoordsLocation,
            matrixLocations,
            textureLocations);
    }
}

export default (props : PictureBoxProps) => {
    const canvasRef = React.createRef<HTMLCanvasElement>();
    const glContext = React.useRef<WebGLRenderingContext | null>();
    const glVertexShader = React.useRef<WebGLShader | null>();
    const glFragmentShader = React.useRef<WebGLShader | null>();
    const glProgram = React.useRef<WebGLProgram | null>();
    const glPositionBuffer = React.useRef<WebGLBuffer | null>();
    const glTextureCoordsBuffer = React.useRef<WebGLBuffer | null>();
    const glPositionLocation = React.useRef<number>();
    const glTextureCoordLocation = React.useRef<number>();
    const glMatrixLocation = React.useRef<WebGLUniformLocation | null>();
    const glTextureLocation = React.useRef<WebGLUniformLocation | null>();
    const texture = React.useRef<SizedWebGLTexture>();
    const CLEAR_COLOR = { r: 0.5, g: 0.5, b: 0.5, a: 1.0 };
    const [ uniforms, setUniforms ] = React.useState({
        shift: 0.02,
        time: 0
    });
    const uniformLocations = React.useRef({
        shift: null as WebGLUniformLocation | null,
        time: null as WebGLUniformLocation | null
    });


    React.useEffect(function initializeCanvasGLContext() {

        //  Be sure we have a valid reference to the canvas
        if ( canvasRef.current !== undefined ) {

            //  Get the WebGLRenderingContext from the canvas
            glContext.current = (canvasRef.current as HTMLCanvasElement).getContext("webgl");

            const gl = (glContext.current as WebGLRenderingContext);
            
            //  Create the Position buffer
            glPositionBuffer.current = gl.createBuffer();
            gl.bindBuffer(gl.ARRAY_BUFFER, glPositionBuffer.current);
            gl.bufferData(gl.ARRAY_BUFFER, new Float32Array([
                0, 0,
                0, 1,
                1, 0,
                1, 0,
                0, 1,
                1, 1
            ]), gl.STATIC_DRAW);

            //  Create the TextureCoords buffer
            glTextureCoordsBuffer.current = gl.createBuffer();
            gl.bindBuffer(gl.ARRAY_BUFFER, glTextureCoordsBuffer.current);
            gl.bufferData(gl.ARRAY_BUFFER, new Float32Array([
                0, 0,
                0, 1,
                1, 0,
                1, 0,
                0, 1,
                1, 1,
            ]), gl.STATIC_DRAW);

            //  Set the 'clearColor' to a fully opaque black
            gl.clearColor(CLEAR_COLOR.r, CLEAR_COLOR.g, CLEAR_COLOR.b, CLEAR_COLOR.a);

            //  Clear the entire canvas to the 'clearColor'
            gl.clear(gl.COLOR_BUFFER_BIT);
        }
    }, 
    [ canvasRef ]);
    
    React.useEffect(function compileVertexShader() {
        if ( glContext.current === null ) {
            return;
        }
        
        const gl = glContext.current as WebGLRenderingContext;
        const vertexShader : string = props.vertexShader || `
            attribute vec4 a_position;
            attribute vec2 a_texcoord;            
            uniform mat4 u_matrix;            
            varying vec2 v_texcoord;
            
            void main() {
                gl_Position = u_matrix * a_position;
                vec2 c = (a_texcoord * vec2(2.0, 1.5)) - vec2(0.5, 0.25);
                v_texcoord = c;
            }`;

        //  Be sure we have the shader created atleast once
        if ( !glVertexShader.current ) {
            glVertexShader.current = gl.createShader(gl.VERTEX_SHADER);
        }

        //  Load the source into the WebGLContext
        gl.shaderSource(glVertexShader.current as WebGLShader, vertexShader);

        //  Compile the shader
        gl.compileShader(glVertexShader.current as WebGLShader);

        if ( gl.getShaderParameter(glVertexShader.current as WebGLShader, gl.COMPILE_STATUS) == false ) {
            const lastError = gl.getShaderInfoLog(glVertexShader.current as WebGLShader);
            console.error("[vertex-shader] ", lastError);
        }

        return () => {
            gl.deleteShader(glVertexShader.current as WebGLShader);
        };
    }, [ glContext, props.vertexShader ]);

    React.useEffect(function compileFragmentShader() {
        if ( glContext.current === undefined ) {
            return;
        }        
        
        const gl = glContext.current as WebGLRenderingContext;
        let fragmentShader : string = props.fragmentShader || `
            precision mediump float;     
            varying vec2 v_texcoord;         
            uniform sampler2D u_texture;
            
            void main() {
                gl_FragColor = texture2D(u_texture, v_texcoord);
            }`;

        //  Be sure we have the shader created atleast once
        if ( glFragmentShader.current === undefined ) {
            glFragmentShader.current = gl.createShader(gl.FRAGMENT_SHADER);
        }

        //  Load the source into the WebGLContext
        gl.shaderSource(glFragmentShader.current as WebGLShader, fragmentShader);

        //  Compile the shader
        gl.compileShader(glFragmentShader.current as WebGLShader);

        if ( gl.getShaderParameter(glFragmentShader.current as WebGLShader, gl.COMPILE_STATUS) == false ) {
            const lastError = gl.getShaderInfoLog(glFragmentShader.current as WebGLShader);
            console.error("[fragment-shader] ", lastError);
        }

        return () => {
            gl.deleteShader(glFragmentShader.current as WebGLShader);
        };
    }, [ glContext, props.fragmentShader ]);

    React.useEffect(function linkTheWebGLProgram() {
        if ( glContext.current && glFragmentShader.current && glVertexShader.current ) {           
            console.log("Program being re-linked");
            const gl = glContext.current as WebGLRenderingContext;
            const oldProgram = glProgram.current;

            glProgram.current = gl.createProgram();

            if ( gl.getShaderParameter(glVertexShader.current as WebGLShader, gl.COMPILE_STATUS) == false ) {
                const lastError = gl.getShaderInfoLog(glVertexShader.current as WebGLShader);
                console.error("[vertex-shader-link] ", lastError);
            }
            
            gl.attachShader(glProgram.current as WebGLProgram, glVertexShader.current as WebGLShader);
            gl.attachShader(glProgram.current as WebGLProgram, glFragmentShader.current as WebGLShader);
            
            gl.linkProgram(glProgram.current as WebGLProgram);

            gl.detachShader(glProgram.current as WebGLProgram, glVertexShader.current as WebGLShader);
            gl.detachShader(glProgram.current as WebGLProgram, glFragmentShader.current as WebGLShader);

            if ( gl.getProgramParameter(glProgram.current as WebGLProgram, gl.LINK_STATUS) === false ) {
                const linkError = gl.getProgramInfoLog(glProgram.current as WebGLProgram);

                console.error("[webgl link-error]", linkError);
            }
            else {
                glPositionLocation.current = gl.getAttribLocation(glProgram.current as WebGLProgram, "a_position");
                glTextureCoordLocation.current = gl.getAttribLocation(glProgram.current as WebGLProgram, "a_texcoord");
                
                glMatrixLocation.current = gl.getUniformLocation(glProgram.current as WebGLProgram, "u_matrix");
                glTextureLocation.current = gl.getUniformLocation(glProgram.current as WebGLProgram, "u_texture");

                //  Gather Uniform Locations
                uniformLocations.current.shift = gl.getUniformLocation(glProgram.current as WebGLProgram, "u_shift");
                uniformLocations.current.time = gl.getUniformLocation(glProgram.current as WebGLProgram, "u_time");
            
                //  Start using the new program
                gl.useProgram(glProgram.current);

                //  Cleanup the old program if there was one
                if ( oldProgram !== undefined ) {
                    gl.deleteProgram(oldProgram);
                }
            } 
        }
    }, [ glVertexShader, glFragmentShader ]);

    React.useEffect(function drawTheImage() {

        //  Be sure we have a valid reference to the WebGL context and a compiled WebGL program
        if ( glContext.current !== undefined && glProgram.current !== undefined ) {
            const gl = glContext.current as WebGLRenderingContext;

            //  Clear the entire canvas to the 'clearColor'
            gl.clear(gl.COLOR_BUFFER_BIT);
            
            //  SET the uniforms
            gl.uniform1f(uniformLocations.current.shift as WebGLUniformLocation, uniforms.shift);
            gl.uniform1f(uniformLocations.current.time as WebGLUniformLocation, uniforms.time);

            //  Draw the image with the current size
            loadAndDrawImage(
                gl, 
                props.src, 
                texture, 
                glProgram.current as WebGLProgram,
                glPositionBuffer.current as WebGLBuffer,
                glTextureCoordsBuffer.current as WebGLBuffer,
                glPositionLocation.current as number,
                glTextureCoordLocation.current as number,
                glMatrixLocation.current as WebGLUniformLocation,
                glTextureLocation.current as WebGLUniformLocation);            
        }
    },
    [ glContext, props.src, props.height, props.width, glProgram, uniforms ]);

    React.useEffect(function resizeCanvas() {
        if ( glContext.current ) {
            const gl = glContext.current as WebGLRenderingContext;
            gl.viewport(0, 0, props.width, props.height);
        }

        if ( canvasRef.current ) {
            canvasRef.current.width = props.width;
            canvasRef.current.height = props.height;
        }
    }, [ glContext, props.width, props.height ]);
    
    React.useEffect(function frameHandlerLoop() {
        let mounted = true;

        const frameHandler = (now : number) => {
            if ( mounted == false ) {
                return;
            }
            if ( props.onFrame ) {
                props.onFrame(now, setUniforms, () => {
                    if ( mounted ) {
                        requestAnimationFrame(frameHandler);
                    }
                });
            }
            else {
                setTimeout(() => requestAnimationFrame(frameHandler), 100);
                setUniforms(uniforms => ({
                    ...uniforms,
                    time: uniforms.time + 6
                }));
            }
        };
        
        frameHandler(0);

        return () => {
            mounted = false;
        };
    }, [ props.onFrame ]);

    React.useEffect(function cleanup() {
        //  NOTE: this function intentionally does nothing except return a cleanup method        

        return () => {
            if ( glContext.current ) {
                const gl = glContext.current;

                gl.useProgram(null);

                if ( glVertexShader.current ) {
                    gl.deleteShader(glVertexShader.current);
                }

                if ( glFragmentShader.current ) {
                    gl.deleteShader(glFragmentShader.current);
                }

                if ( glProgram.current ) {
                    gl.deleteProgram(glProgram.current);
                }

                if ( texture.current && texture.current.glTexture ) {
                    gl.deleteTexture(texture.current.glTexture);
                }
            }
        };
    }, []);
    
    return (
        <canvas 
            ref={canvasRef}
            style={props.style || {}}
            width={props.width} 
            height={props.height}>
        </canvas>
    )
};