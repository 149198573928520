import React from 'react';
import './App.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import LandingPage from './pages/LandingPage';
import AboutPage from './pages/AboutPage';
import BlogPage from './pages/BlogPage';
import CreationsPage from './pages/CreationsPage';
import DraftsPage from './pages/DraftsPage';
import EverythingPage from './pages/EverythingPage';

function App() {
  
  return (
    <Router>
      
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route path="/about" component={AboutPage} />
        <Route path="/blog" component={BlogPage} />
        <Route path="/creations" component={CreationsPage} />
        <Route path="/drafts" component={DraftsPage} />
        <Route path="/everything" component={EverythingPage} />
      </Switch>
    </Router>
  )
}

export default App;
